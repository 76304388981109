import React from 'react';
import { BannerMonthlyPromo } from 'views/offer/banner-monthly-promo';
import { PATHS } from 'constants/paths';
import { treatmentsHubs } from 'constants/treatment-hubs';

import { TreatmentsList } from '../components/treatments-list';

export const useMesotherapyContent = () => {
  return {
    title: 'Zabiegi Mezoterapii Igłowej',
    sections: [
      {
        type: 'section',
        subtitle: 'Na Czym Polega Mezoterapia Igłowa?',
        nodes: [
          {
            type: 'paragraph',
            content:
              '<b>Mezoterapia igłowa </b> to zaawansowana technika medycyny estetycznej, która polega na mikroiniekcjach składników aktywnych, takich jak kwas hialuronowy, witaminy czy aminokwasy, bezpośrednio w skórę. Ta metoda jest ceniona za swoją skuteczność w odmładzaniu, nawilżaniu i regeneracji skóry. Dzięki precyzyjnemu dostarczaniu substancji odżywczych, mezoterapia igłowa intensywnie poprawia kondycję skóry, stymulując jej naturalne procesy odnowy.',
          },
          {
            type: 'paragraph',
            content:
              'Mezoterapia igłowa jest szczególnie polecana dla osób, które chcą poprawić jakość swojej skóry bez konieczności poddawania się bardziej inwazyjnym zabiegom chirurgicznym. Podczas zabiegu, specjalnie dobrana mieszanka składników aktywnych jest dostarczana bezpośrednio do miejsc, które wymagają intensywnej regeneracji lub nawilżenia. To sprawia, że mezoterapia jest niezwykle <b>efektywna w walce z oznakami starzenia, odwodnieniem skóry</b>, a także w leczeniu problemów takich jak blizny, rozstępy czy cellulit. Dzięki indywidualnie dostosowanym koktajlom leczniczym, mezoterapia igłowa może być skutecznie zastosowana praktycznie na każdym obszarze skóry, co czyni ją uniwersalnym rozwiązaniem dla wielu problemów dermatologicznych i estetycznych.',
          },
        ],
      },
      {
        type: 'section',
        subtitle: 'Czy Wybrać Mezoterapię Igłową czy Mikroigłową?',
        nodes: [
          {
            type: 'paragraph',
            content:
              'Wybór między mezoterapią igłową a mikroigłową zależy od potrzeb skóry oraz oczekiwanych efektów. Mezoterapia igłowa jest zalecana do głębszych zabiegów regeneracyjnych i intensywnego nawilżenia, ponieważ pozwala na głębokie wprowadzenie substancji aktywnych. Natomiast mezoterapia mikroigłowa, wykorzystująca drobniejsze igły, jest idealna dla osób poszukujących mniej inwazyjnych procedur, które skupiają się na poprawie tekstury skóry i minimalizacji blizn oraz rozstępów.',
          },
        ],
      },
      {
        type: 'section',
        subtitle: 'Rodzaje Zabiegu Mezoterapii Igłowej',
        nodes: [
          {
            type: 'node',
            content: (
              <TreatmentsList offers={treatmentsHubs.mesotherapy.treatments} />
            ),
          },
          {
            type: 'paragraph',
            content:
              'Mezoterapia igłowa to wszechstronna technika, która może być stosowana w różnych obszarach, aby sprostać unikalnym potrzebom skóry pacjenta. W Klinice Medycyny Estetycznej IDEALE oferujemy kilka specjalistycznych rodzajów tego zabiegu, dostosowanych do różnych obszarów ciała i różnych problemów skórnych.',
          },
          {
            type: 'paragraph',
            content:
              '<b>Mezoterapia na twarz</b> jest jednym z najpopularniejszych zabiegów, który intensywnie regeneruje, nawilża i rewitalizuje skórę twarzy. Stosowanie mikroiniekcji pozwala na precyzyjne dostarczenie składników aktywnych, takich jak witaminy, minerały, aminokwasy oraz kwas hialuronowy, bezpośrednio do głębszych warstw skóry. Zabieg ten jest idealny dla osób poszukujących sposobu na odzyskanie młodzieńczego blasku i zniwelowanie oznak starzenia.',
          },
          {
            type: 'paragraph',
            content:
              '<b>Mezoterapia pod oczy</b> skupia się na delikatnym obszarze wokół oczu, gdzie skóra jest szczególnie wrażliwa i skłonna do przedwczesnego starzenia. Zabieg ten pomaga zmniejszyć cienie i worki pod oczami, a także wygładzić drobne linie i zmarszczki. Dzięki specjalnie dobranej mieszance składników aktywnych, mezoterapia pod oczy efektywnie poprawia mikrocyrkulację i zwiększa elastyczność skóry, przywracając jej młodzieńczy wygląd.',
          },
          {
            type: 'paragraph',
            content:
              '<b>Mezoterapia skóry głowy</b> jest stosowana w celu poprawy kondycji włosów i skóry głowy. Zabieg ten jest szczególnie polecany dla osób borykających się z problemami takimi jak nadmierne wypadanie włosów, łysienie czy osłabienie struktury włosów. Mezoterapia skóry głowy dostarcza niezbędnych składników odżywczych, które stymulują wzrost nowych włosów, wzmacniają cebulki włosowe i poprawiają ogólną zdrowotność skóry głowy.',
          },
          {
            type: 'paragraph',
            content:
              'Każdy z tych zabiegów jest dostosowany do indywidualnych potrzeb i oczekiwań pacjentów, co pozwala na osiągnięcie optymalnych rezultatów i zadowolenie z efektów terapii.',
          },
          { type: 'node', content: <BannerMonthlyPromo /> },
        ],
      },
      {
        type: 'section',
        subtitle: 'Efekty Mezoterapii Igłowej',
        nodes: [
          {
            type: 'paragraph',
            content:
              'Efekty zabiegu mezoterapii igłowej są widoczne niemal natychmiast po zabiegu i stopniowo się poprawiają w trakcie serii zabiegów. Pacjenci mogą zauważyć znaczące polepszenie nawilżenia skóry, jej elastyczności oraz ogólnej jędrności. Mezoterapia skutecznie redukuje także drobne linie i zmarszczki, a regularne sesje mogą przyczynić się do długotrwałej poprawy jakości skóry. Jest to zabieg wybierany nie tylko ze względów estetycznych, ale również leczniczych, pomagając w przypadkach takich jak utrata gęstości skóry czy przewlekłe problemy skórne.',
          },
        ],
      },
      {
        type: 'bannerSection',
      },
      {
        type: 'section',
        subtitle: 'Zabieg Mezoterapii Tylko w IDEALE',
        nodes: [
          {
            type: 'paragraph',
            content:
              '<b>IDEALE Medycyna Estetyczna to gabinet stworzony przez kobietę dla kobiet</b>. Dążymy do tego aby nasi Pacjenci poczuli się piękni i dostrzegli w sobie wyjątkowe, unikatowe cechy. Podkreślamy to co interesujące. Słuchamy i doradzamy. Nasz doświadczony lekarz medycyny estetycznej ułoży dla Ciebie plan, indywidualnie dopasuje zabiegi mezoterapii igłowej, doradzi w temacie pielęgnacji skóry, dzięki czemu wyjdziesz od nas piękna, naturalna i promienna, a Twoja skóra będzie wyglądać na młodszą i bardziej wypoczętą. Nasza klinika mieści się w Warszawie na Woli, tuż przy stacji metra Młynów zapewniając łatwy dojazd naszym pacjentom. Wokół kliniki znajduje się również wiele miejsc parkingowych.',
          },
          {
            type: 'paragraph',
            content: `W IDEALE skupiamy się na dostarczaniu skutecznych i bezpiecznych <a href="${PATHS.OFFER}" >zabiegów medycyny estetycznej</a>, które czerpiemy prosto z międzynarodowych kongresów i szkoleń. Dążymy do zapewnienia najwyższej jakości usług, a nasz zespół stale poszerza swoją wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom. Cena wybranego zabiegu jest dostępna w naszym cenniku.`,
          },
          {
            type: 'paragraph',
            content: `Razem z nami odkryjesz potencjał swojej urody i swoją unikatowość. Poczujesz się zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu, abyś mogła cieszyć się piękną, zadbaną i młodą skórą.`,
          },
        ],
      },
      {
        type: 'newsletter',
      },
    ],
  };
};
